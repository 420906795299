import {request} from '@/util/request'

export function getList(params, data) {
  return request({
    url:
      '/api/SPV/GetList?intPageIndex=' +
      params.intPageIndex +
      '&intPageSize=' +
      params.intPageSize +
      '&strOrderByFileds=' +
      params.strOrderByFileds,
    method: 'post',
    data: data,
  })
}

export function doAdd(data) {
  return request({
    url: '/api/SPV/Add',
    method: 'post',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/api/SPV/Update',
    method: 'put',
    data,
  })
}

export function doDelete(params) {
  return request({
    url: '/api/SPV/DeleteById',
    method: 'delete',
    params,
  })
}

export function getSPVOptions(data) {
  return request({
    url: '/api/SPV/GetOptions',
    method: 'post',
    data,
  })
}

export function getPeriodInfo(params) {
  return request({
    url: '/api/SPV/GetPeriodInfo',
    method: 'get',
    params,
  })
}
