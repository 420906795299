<template>
  <div style="margin: 14px">
    <van-form @submit="onSubmit">
      <div style="text-align: center;background-color: #ebebeb;color: #646566;height: 60px;line-height: 60px">
        <span style="font-size: 24px;margin-top: 5px"><strong>政协提案申请</strong></span>
      </div>
      <van-field
        v-model="formData.Meet_Name"
        name="会议名称"
        label="会议名称"
        readonly
      />
      <van-field
        readonly
        clickable
        :value="date"
        label="登记时间"
        placeholder="点击选择时间"
        :rules="[{ required: true, message: '请选择时间' }]"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          v-model="formData.Date_App"
          type="date"
          @confirm="onConfirm"
          @cancel="showPicker = false"
          :formatter="formatter"
        />
      </van-popup>
      <van-field
        v-model="formData.Case_Status_Name"
        name="会议状态"
        label="会议状态"
        readonly
      />
      <van-field name="radio" label="身份">
        <template #input>
          <van-radio-group v-model="formData.PPS_Type_ID" direction="horizontal">
            <van-radio name="2">委员</van-radio>
            <van-radio name="4">党派团体</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="formData.Case_Type_Name"
        readonly
        name="案别"
        label="案别"
      />
      <van-field
        v-model="formData.Staff_Name"
        readonly
        name="提案者"
        label="提案者"
      />
      <van-field
        v-model="Staff_Name_Str"
        readonly
        name="附议人"
        label="附议人"
        @click="openTree()"
        placeholder="点击选择附议人"
      />
      <van-field
        v-model="formData.DGT_Name"
        name="联系人"
        label="联系人"
        :rules="[{ required: true, message: '请输入联系人' }]"
      />
      <van-field
        v-model="formData.DGT_Tel"
        name="联系电话"
        label="联系电话"
        :rules="[{ required: true, message: '请输入联系电话' }]"
      />
      <van-field
        v-model="formData.DGT_Unit"
        name="工作地址"
        label="工作地址"
        :rules="[{ required: true, message: '请输入工作地址' }]"
      />
      <van-field
        v-model="formData.Address"
        name="联系地址"
        label="联系地址"
        :rules="[{ required: true, message: '请输入联系地址' }]"
      />
      <van-field name="checkboxGroup" label="性质" :rules="[{ required: true, message: '请选择性质' }]">
        <template #input>
          <van-checkbox-group v-model="formData.Nature_Arr" direction="horizontal">
            <van-checkbox name="1" >当年</van-checkbox>
            <van-checkbox name="2" >多年重复</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field
        v-model="formData.Case_Action"
        name="案由(标题)"
        label="案由(标题)"
        placeholder="请输入案由(标题)"
        :rules="[{ required: true, message: '请输入案由(标题)' }]"
      />
      <van-field label="内容" placeholder="请在下方输入内容" readonly/>
      <van-cell>
        <el-input
          v-model="formData.Content"
          type="textarea"
          show-word-limit
          :autosize="{ minRows: 10, maxRows: 10 }"
          :style="{ width: '100%' }"
        />
      </van-cell>
      <van-field>
        <template #input>
          <upload-file ref="uploadFile" @fetch-data="getFileList" />
        </template>
      </van-field>
      <van-field
          v-model="formData.Hop_ORG"
          name="希望承办单位"
          label="希望承办单位"
          placeholder="请输入希望承办单位"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" :loading="loading">提交</van-button>
      </div>
    </van-form>
    <fyr ref="fyr" @getTree="getTree" />
  </div>
</template>

<script>
  import fyr from '@/views/fyr'
  import uploadFile from "@/components/UploadFile"
  import moment from 'moment'
  import store from "@/store";
  import {fileType} from '@/config/setting.config'
  import {Notify, Toast} from 'vant';
  import { getPeriodInfo } from '@/api/sessionManagement'
  import { getUserInfo } from '@/api/staffManagement'
  import { addApp, getAppInfo, updateApp } from '@/api/appManagement'
  import { compareTextSimilarity } from "@/api/similarManagement";
  import {Emas} from "@/assets/js/Emas";
  export default {
    name: "addApp",
    components: { fyr, uploadFile },
    data() {
      return {
        date: '',
        showPicker: false,
        loading:false,
        Staff_ID_Arr: [], //附议人ID数组
        Staff_Name_Arr: [], //附议人ID数组
        Staff_ID_Str: '',
        Staff_Name_Str: '',
        periodInfo:{},
        userInfo: {},
        FileList:[],
        formData: {
          Type: 2, //政协
          ID: 0, //附件id
          Meet_Name: '',
          Period_ID: null,
          Date_App: moment(new Date()).format('yyyy-MM-DD'), //登记时间
          Case_Status: null, //会议状态
          Case_Status_Name: '', //会议状态名称
          Case_Type_ID: 1, //案别
          Case_Type_Name: '提案',
          Staff_Name: '', //领衔人 建议者
          DGT_ID: 0, //领衔人id
          DGT_Name: '', //联系人
          DGT_Tel: '', //联系电话
          DGT_Unit: '', //联系地址
          Address: '', //工作地址
          Is_Public: 1, //是否公开
          Nature_Arr: [], //性质
          Nature: '', //性质
          Case_Action: '', //案由（标题）
          Content: '', //内容办法及建议
          Hop_ORG: '', //希望承办的单位
          DGT_Num: null, //附议人数量
          DGT_List: '', //附议人列表
          PPS_Type_ID: "2", // 身份
          fileList: [], //附件列表
          Operator: 0,
          This_Status: 1, //状态
          File_ID: 0, //附件id
          DLG_ID: 0, //代表团
          Is_All: 0, //全会提案
          Is_IST: 0,
          Is_Excellent: 0,
          Is_Main: 0,
          Case_Property_ID:null,
        }
      }
    },
    created() {
      this.fileType = fileType
      this.date =  moment(new Date()).format('yyyy-MM-DD')
      this.getPeriodInfo()
      this.getUserInfo()

    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/addApp", "新增", "zzdzx.yy.gov.cn/addApp")
    },
    methods:{
      async onSubmit() {
        let userInfo = store.getters['user/userInfo']
        this.formData.Operator = userInfo.user_ID
        this.formData.Nature = this.formData.Nature_Arr.join('|')
        this.formData.fileList = this.handleFile(this.FileList)
        if (this.checkContent()) {
          let Max = await this.checkSimilar()
          if(Max > 80) {
            Toast.fail('相似度大于80%，请重新填写');
          } else{
            Toast.loading({
              duration: 0, // 持续展示 toast
              message: '正在保存...',
              forbidClick: true,
            });
            this.loading = true
            const { msg } = await addApp(this.formData)
            this.loading = false
            Toast.clear();
            Toast.success(msg);
            this.close()
            await this.$router.push('/cppcc')
          }
        }
      },

      close() {
        this.FileList = []
        this.Staff_Name_Str = ''
        this.Staff_ID_Str = ''
        this.Staff_Name_Arr = []
        this.Staff_ID_Arr = []
      },

      //打开附议人
      openTree() {
        this.$refs['fyr'].showTree(2, this.Staff_ID_Arr)
      },
      getTree(obj) {
        this.Staff_Name_Str = ''
        this.Staff_ID_Arr = []
        if (obj != null) {
          let Staff_Name_Arr = obj.Staff_Name_Arr
          let Staff_ID_Arr = obj.Staff_ID_Arr
          this.Staff_ID_Arr = Staff_ID_Arr
          this.Staff_Name_Arr = Staff_Name_Arr
          this.Staff_Name_Str = Staff_Name_Arr.join(',') //人事名字字符串
          this.Staff_ID_Str = Staff_ID_Arr.join(',') //人事ID字符串
          this.formData.DGT_List = this.Staff_ID_Str
          this.formData.DGT_Num = Staff_ID_Arr.length
        } else {
          this.formData.DGT_List = ''
          this.formData.DGT_Num = null
        }
      },

      checkContent() {
        if(this.formData.Content.length <= 300) {
          Notify({ type: 'danger', message: '内容不能少于300字' });
          return false
        }
        if(this.formData.Nature === '') {
          Notify({ type: 'danger', message: '请选择案件性质' });
          return false
        }
        return true
      },

      async checkSimilar() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在比较案件相似度...',
          forbidClick: true,
        });
        this.loading = true
        const { data } = await compareTextSimilarity({
          Content: this.formData.Content,
          App_ID: 0,
          Type: 2,
          App_Type: 2,
        })
        this.loading = false
        Toast.clear();
        this.list = data
        if(data.length > 0){
          this.descSimilar()
          return this.list[0].similarity
        }
        return 0
      },

      descSimilar() {
        this.list.sort(function (a, b) {
          return b.similarity - a.similarity
        })
      },

      getFileList(obj) {
        this.FileList = obj
      },

      //处理文件
      handleFile(fileList) {
        console.log(fileList)
        if (fileList.length > 0) {
          let arr = []
          fileList.forEach(function (item) {
            let a = ''
            a = item.name + '|' + item.url
            arr.push(a)
          })
          return arr
        } else {
          return null
        }
      },

      onConfirm(date) {
        this.date =  moment(date).format('yyyy-MM-DD')
        this.showPicker = false;
      },
      //格式化时间
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`;
        } else if (type === 'month') {
          return `${val}月`;
        }else if (type === 'day') {
          return `${val}日`;
        }
        return val;
      },

      //获取会议详情
      async getPeriodInfo() {
        const { data } = await getPeriodInfo({ Type: 2 })
        let periodInfo = data
        this.formData.Meet_Name = periodInfo.meet_Name
        this.formData.Period_ID = periodInfo.id
        this.formData.Case_Status = periodInfo.show_Status
        this.formData.Case_Status_Name = periodInfo.show_Status === 1 ? '大会期间' : '闭会期间'
      },

      //获取用户详情
      async getUserInfo() {
        /*Toast.loading({
          duration: 0, // 持续展示 toast
          message: '加载中...',
          forbidClick: true,
        });*/
        let userInfos = store.getters['user/userInfo']
        const { data } = await getUserInfo({ User_ID: userInfos.user_ID })
        let userInfo = data
        this.formData.Staff_Name = userInfo.staff_Name
        this.formData.DGT_ID = userInfo.user_ID
        this.formData.DGT_Name = userInfo.staff_Name
        this.formData.DGT_Tel = userInfo.mobile
        this.formData.DGT_Unit = userInfo.remark
        this.formData.Address = userInfo.native_place
        this.formData.DLG_ID = userInfo.dlG_ID
        this.formData.Operator = userInfo.user_ID
        Toast.clear()
      },
    }
  }
</script>

<style scoped>

</style>