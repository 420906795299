import {request} from "@/util/request";


export function compareTextSimilarity(data) {
  return request({
    url: '/api/Similar/CompareTextSimilarity',
    method: 'post',
    data,
  })
}
